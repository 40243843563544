import "core-js/modules/es.array.push.js";
import { mixins } from "@/plugins/mixins";
import { ElLoading, ElMessage } from "element-plus";
import 'element-plus/es/components/loading/style/css'
import 'element-plus/es/components/message/style/css'

export default {
  name: "feeRuleAdd",
  mixins: [mixins],
  data() {
    return {
      edit: false,
      carTypeList: [],
      formModel: {
        wxRedit: 0,
        deposit: 0
      },
      formRules: {
        wxRedit: [{
          required: true,
          message: '请输入微信免押信用分',
          trigger: 'blur'
        }],
        deposit: [{
          required: true,
          message: '请输入押金金额',
          trigger: 'blur'
        }]
      }
    };
  },
  methods: {
    feeTypeChange(feeType) {
      if (feeType === '按时收费') {
        this.formModel.items = [{
          seq: 1,
          duration: 0,
          fee: 0,
          editable: true
        }];
      } else {
        this.formModel.items = [];
      }
    },
    addItem() {
      this.formModel.items.push({
        seq: this.formModel.items.length + 1,
        duration: 0,
        fee: 0,
        editable: true
      });
    },
    deleteItem(delItem) {
      this.formModel.items.forEach(function (item, index, arr) {
        if (item.seq === delItem.seq) {
          arr.splice(index, 1);
        }
      });
      // 重新排序
      this.formModel.items.forEach((item, index) => {
        item.seq = index + 1;
      });
    },
    submitForm() {
      this.$refs.formRef.validate(valid => {
        if (valid) {
          const callBack = res => {
            if (res.code === 200) {
              ElMessage({
                message: res.message,
                type: 'success'
              });
              this.$router.back();
            } else {
              ElMessage.error(res.message);
            }
          };
          if (this.edit) {
            this.$api.business.depositRule.update(this.formModel).then(callBack);
          } else {
            this.$api.business.depositRule.create(this.formModel).then(callBack);
          }
        }
      });
    }
  },
  beforeMount() {
    if (this.$route.query.id) {
      this.edit = true;
      const loading = ElLoading.service();
      this.$api.business.feeRule.detail(this.$route.query.id).then(res => {
        if (res.code === 200) {
          this.formModel = res.data;
        } else if (res.code === 121601) {
          this.$router.replace({
            name: '404'
          });
        } else {
          ElMessage.error(res.message);
          this.$router.back();
        }
      }).finally(() => {
        loading.close();
      });
    }
  }
};